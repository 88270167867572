<template>
  <div id="calendar-component">
    <div class="flex flex-col justify-center items-center">
      <p class="py-5 textxl md:text-2xl font-semibold w-full md:w-5/12 text-center">
        When do you need Physician Coverage?
        Select preferred date and time
      </p>
      <div class="mb-6">
        <vc-date-picker
          is-range
          v-model="range"
          :min-date='new Date()'
        />
      </div>
    </div>
    <div
      class="w-full md:w-5/12 mx-auto"
      v-if="dateRange.length > 0">
      <h2 class="py-5 font-semibold text-xl josefin">Select Time</h2>
      <div
        v-for="(dates, index) in dateRange"
        :key="index"
      >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <h5 class="font-md font-semibold">{{formatDate(dates.date)}}</h5>
          <p
            @click.prevent="addMore(index)"
            class="text-right uppercase pt-4 md:pt-0 text-xs cursor-pointer text-blue font-medium"
          >
            Add Time Period
          </p>
        </div>
        <div
          v-for="(dateRng, dateIndex) in dates.activeDates"
          :key="dateIndex"
          class="my-4"
        >
          <el-form>
            <div class="relative mb-8">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                <div>
                  <p class="text-md py-3">Start Time</p>
                  <el-time-select
                    v-model="dateRng.start_time"
                    :picker-options="{
                      start: '08:30',
                      step: '00:15',
                      end: '18:30'
                    }"
                    placeholder="Choose Start Time">
                  </el-time-select>
                </div>
                <div class="ml-0 md:ml-4">
                  <p class="text-md py-3">End Time</p>
                  <div>
                    <el-time-select
                    v-model="dateRng.end_time"
                    :picker-options="{
                      start: '08:30',
                      step: '00:15',
                      end: '18:30'
                    }"
                    placeholder="Choose End Time">
                  </el-time-select>
                  </div>
                </div>
              </div>
              <div
                @click="deleteItem(index, dateIndex)"
                class="absolute top-0 delete_icon"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5901 12L6.29006 16.29C6.10176 16.4783 5.99597 16.7337 5.99597 17C5.99597 17.2663 6.10176 17.5217 6.29006 17.71C6.47837 17.8983 6.73376 18.0041 7.00006 18.0041C7.26636 18.0041 7.52176 17.8983 7.71006 17.71L12.0001 13.41L16.2901 17.71C16.4784 17.8983 16.7338 18.0041 17.0001 18.0041C17.2664 18.0041 17.5218 17.8983 17.7101 17.71C17.8984 17.5217 18.0042 17.2663 18.0042 17C18.0042 16.7337 17.8984 16.4783 17.7101 16.29L13.4101 12L17.7101 7.71C17.8038 7.61703 17.8782 7.50643 17.929 7.38457C17.9797 7.26272 18.0059 7.13201 18.0059 7C18.0059 6.86799 17.9797 6.73728 17.929 6.61542C17.8782 6.49356 17.8038 6.38296 17.7101 6.29C17.6171 6.19627 17.5065 6.12187 17.3846 6.07111C17.2628 6.02034 17.1321 5.9942 17.0001 5.9942C16.868 5.9942 16.7373 6.02034 16.6155 6.07111C16.4936 6.12187 16.383 6.19627 16.2901 6.29L12.0001 10.59L7.71006 6.29C7.6171 6.19627 7.5065 6.12187 7.38464 6.07111C7.26278 6.02034 7.13207 5.9942 7.00006 5.9942C6.86805 5.9942 6.73734 6.02034 6.61548 6.07111C6.49362 6.12187 6.38303 6.19627 6.29006 6.29C6.19633 6.38296 6.12194 6.49356 6.07117 6.61542C6.0204 6.73728 5.99426 6.86799 5.99426 7C5.99426 7.13201 6.0204 7.26272 6.07117 7.38457C6.12194 7.50643 6.19633 7.61703 6.29006 7.71L10.5901 12Z" fill="#292929"/>
                </svg>
              </div>
            </div>
          </el-form>
        </div>
        <p
          v-if="index === 0"
          @click="applyToAll"
          class="-mt-3 mb-2 text-xs text-blue uppercase font-bold cursor-pointer"
        >Apply to all</p>
      </div>
      <div class="flex justify-center items-center">
        <button
          class="rounded-full bg-primary px-6
          py-3 text-white w-52 font-semibold
          text-lg mt-8 focus:outline-none"
          @click="next"
          :disabled="disabled"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { DateInfo } from 'v-calendar';
import dayjs from 'dayjs';

export default {
  data() {
    return {
      range: null,
      date: new Date(),
      dateRange: []
    }
  },
  computed: {
    // Setting attributes to the date picker
    attributes() {
      return this.dateRange.map(date => ({
        highlight: true,
        dates: date,
      }));
    },
    // disable past days
    disabled() {
      return this.dateRange.every(d => {
        return d.start_time === '' || d.end_time === ''
      })
    },
  },
  watch: {
    range: {
      deep: true,
      handler(x) {
        const result = [];
        if (!x) return result;
        const di = new DateInfo(x);
        di.iterateDatesInRange(x, ({day}) => result.push(day));
        const newArr = result.map((item, i) => {
          return {
            id: i + 1,
            date: item.date,
            activeDates: [
              {
                start_time: '',
                end_time: ''
              }
            ]
          }
        })
        const sortedArr =  newArr.sort((a, b) => {
          return (a.date) - (b.date);
        })
        this.dateRange = sortedArr
      }
    }
  },
  methods: {
    applyToAll() {
      const initial = this.dateRange[0].activeDates;
      for (let i = 1; i < this.dateRange.length; i++) {
        this.dateRange[i].activeDates = initial;
      }
    },
    formatDate(date) {
      return dayjs(date).format('dddd, MMMM D, YYYY')
    },
    // This method formats the selected date to fit our current implementation
    // withoutTime(dateTime) {
    //   let date = new Date(dateTime.getTime());
    //   date.setHours(0, 0, 0, 0);
    //   return date;
    // },
    // adds a new object to the selected dates array
    addMore(i) {
      this.dateRange[i].activeDates.push({
        start_time: '',
        end_time: ''
      })
    },
    // deletes the selected date in the array
    deleteItem(i, z) {
      this.dateRange[i].activeDates.splice(z, 1)
      this.dateRange.splice(i, 1);
    },
    // Routes the page to the next step 
    next() {
      // console.log(this.newData.dateRange);
      this.$emit('handleNext')
    }
  }
}
</script>

<style scoped>
.px-73 {
  padding-left: 19.5rem;
  padding-right: 19.5rem;
}

* >>> .el-input__inner {
  background: #EEEEEE !important;
  outline: 0;
  border: none !important;
}

.el-input__inner {
  background: #F8F8F8;
  border: none;
  border-radius: 0.5rem;
}

.pl-left-2xl {
  padding-left: 28.5rem;
}

* >>> .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100% !important;
}
</style>