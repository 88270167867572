<template>
  <div>
    <div class="flex flex-col justify-center items-center">
      <h2 class="py-5 text-2xl font-semibold w-5/12 text-center mt-5">Booking confirmed!</h2>
      <p class="py-6 text-xs">You’ve booked an appointment with</p>
      <img src="@/assets/img/physician1.svg" alt="" class="rounded-full w-16 h-16">
      <p class="font-semibold py-1 text-secondary">Jason Whooper, MD</p>
      <p class="text-xs text-secondary">Cardiologist</p>
    </div>
    <div class="w-6/12 m-auto">
      <div class="grid grid-cols-3 gap-10 mt-8">
        <div class="flex flex-col">
          <p class="pb-1">Sunday, March 11, 2021</p>
          <p class="text-sm">9:00 am - 9:30 am</p>
          <p class="text-sm">12:15 pm - 1:45 pm</p>
        </div>
        <div class="flex flex-col">
          <p class="pb-1">Monday, March 12, 2021</p>
          <p class="text-sm">9:00 am - 9:50 am</p>
        </div>
        <div class="flex flex-col">
          <p class="pb-1">Monday, March 12, 2021</p>
          <p class="text-sm">9:00 am - 9:30 am</p>
          <p class="text-sm">12:15 pm - 1:45 pm</p>
          <p class="text-sm">3:00 pm - 4:00 pm</p>
        </div>
      </div>
      <div>
        <button
          class="py-3 w-full font-semibold rounded-full
         bg-primary text-white mt-10 text-center"
         @click.prevent="goToProfile"
         >Add to calendar </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToProfile() {
      this.$router.push('/')
    }
  }
}
</script>

<style>

</style>