<template>
  <div class="relative pt-20">
    <div class="flex flex-col justify-center items-center">
      <k-progress :percent="percent"></k-progress>
    </div>
    <div v-if="active === 1">
      <business-calendar
        @handleNext="handleNext"
      ></business-calendar>
    </div>
    <div v-if="active === 2">
      <Search @handleNext="handleNext" @handleBack="handleBack" />
    </div>
    <div v-if="active === 3">
      <Payment @handleNext="handleNext" @handleBack="handleBack" />
    </div>
    <div v-if="active === 4">
      <Confirm @handleNext="handleNext" @handleBack="handleBack" />
    </div>
  </div>
</template>

<script>
import BusinessCalendar from '../components/Calendar'
import Search from '../components/Search'
import Payment from '../components/Payment.vue'
import Confirm from '../components/Confirm.vue'

export default {
  components: {
    BusinessCalendar,
    Search,
    Payment,
    Confirm
  },
  data() {
    return {
      percent: 25,
      active: 1
    }
  },
  methods: {
    handleNext() {
      if(this.active ++ > 4)this.active = 1;
      this.percent = this.percent + 25;
    },
    handleBack() {
      if(this.active !== 1) {
        this.active = this.active - 1;
        this.percent = this.percent - 25;
      }
    }
  }
}
</script>

<style>
  .back_button {
    position: absolute;
    top: 10%;
    left: 3%;
  }
</style>